<body class="hold-transition login-page dark-mode">

<div class="login-box">
    <div class="login-logo">
      <a href="/"><img style="width: 80%;" src="{{varGlobal.RUTA_IMAGEN_LOGO}}" /><b>{{varGlobal.TITULO_LOGIN}} </b></a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Iniciar sesión</p>

        <form name="loginForm" [formGroup]="loginForm" novalidate>
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="RUT" formControlName="RUT">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-male"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Contraseña" formControlName="PASSWORD">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- /.col -->
            <div class="col-12">
              <button class="btn btn-primary btn-block" style="background: #71299A;border: 2px solid #71299A;" (click)="login();">Iniciar</button>
            </div>
            <!-- /.col -->
          </div>
        </form>

      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
  <!-- /.login-box -->
</body>
