import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import {IntranetoldService} from './../../../api/intranetold.service'
import { StockComercialDetalleItemBodegaComponent } from './detalleArticuloBodega';
import {variableGlobal} from "../../../global.enum";
import Swal from 'sweetalert2'
import { ExportToCsv } from 'export-to-csv';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-stock-comercial',
  templateUrl: './stock-comercial.component.html',
  styleUrls: ['./stock-comercial.component.sass','./stock-comercial.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockComercialComponent implements OnInit {

  constructor(
    private _api:IntranetoldService,
    private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,

  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  data:any = [];
  dataCopia:any = [];
  OPLN:any;
  listaPrecio:number = 1;
  listaCategorias:any;
  categoriaSeleccionada:string = "";
  listaFamilia:any;
  familiaSeleccionada:string = "";
  listaSubFamilia:any;
  subFamiliaSeleccionada:string = "";
  public configuration: Config;
  public columns: Columns[];

  carrito:any = [];
  carritoCantidad:any;
  carritoModal:any;
  carritoTotalNeto:any = null;
  carritoTotalBruto:any = null;
  ROL = localStorage.getItem("ROL")
  perfiles;
  login;
  perfilColaborador;

  mensajeAlert = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  })

  borrarItemCarrito(index){

    this.carrito.splice(index,1);

    this.actualizarCartTotal();

  }

  addCart(row:any){

    const maxLength = 20;

    row.ItemName = row.ItemName.substring(0, maxLength);

    this.carrito.push(row);

    this.mensajeAlert.fire({
      icon: 'success',
      title: row.ItemName+' agregado correctamente.'
    })

    this.actualizarCartTotal();

  }

  actualizarCartTotal(){

    this.carritoCantidad = this.carrito.length;

    this.carritoTotalNeto = null;

    this.carritoTotalBruto = null;

    this.carrito.forEach(element => {
      this.carritoTotalNeto = this.carritoTotalNeto + element.Price;
    });

    this.carrito.forEach(element => {
      this.carritoTotalBruto = this.carritoTotalBruto + element.PriceBruto.toFixed(2);
    });

  }

  verCart(){
    this.carritoModal = !this.carritoModal;
  }




  async ngOnInit(): Promise<void> {
    console.log("ROL")
    console.log("ROL",this.ROL)
    this.carritoModal = false;

    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      item => {
        var itemx:any = item;
        this.login = itemx.datos.login;
        console.log("LOGIN",this.login)
        //console.log("cargando url con esta url ", this.currentURL);
        //console.log(this.login);
      }
    );

    this.perfiles = await this._api.getPerfilAll();
    this.perfiles = this.perfiles.objeto;

    this.perfiles.forEach(element => {
      if (element.NOMBRE == "COLABORADOR") {
        this.perfilColaborador = element._id;
      }
    });

    this.cargaTabla();

  }
  @ViewChild('frmItemCode', { static: true }) frmItemCode: TemplateRef<any>;
  @ViewChild('frmPrice', { static: true }) frmPrice: TemplateRef<any>;
  @ViewChild('frmPriceBruto', { static: true }) frmPriceBruto: TemplateRef<any>;
  @ViewChild('cart', { static: true }) cart: TemplateRef<any>;

  // SE muestra este mensaje durante mucho tiempo, solucionar
  async cargaTabla()
  {
    Swal.fire({
      title: 'Espere por favor!',
      html: 'Se esta cargando stock',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
          Swal.showLoading()
      }
  });

     if (this.login.PERFIL != this.perfilColaborador) {
      this.columns = [
        { key: '', title: "", cellTemplate: this.cart,searchEnabled:false },
        { key: 'ItemCode', title: "Codigo", cellTemplate: this.frmItemCode },
        { key: 'U_cod_equiva', title: "Cod Equ" },
        { key: 'ItemName', title: 'Descripción' },
        { key: 'ItmsGrpNam', title: 'Categoria' },
        { key: 'OnHand', title: 'En stock' },
        { key: 'IsCommited', title: 'NV' },
        { key: 'OnOrder', title: 'Por llegar' },
        { key: 'Available', title: 'Disponible' },
        { key: 'U_Ubicacion', title: 'Ubicación' },
        { key: 'U_Ubica2', title: 'Ubicación 2' },
        { key: 'Price', title: 'Precio Neto', cellTemplate: this.frmPrice },
        { key: 'PriceBruto', title: 'Precio Bruto', cellTemplate: this.frmPriceBruto }
      ];
     }else{
      this.columns = [
        { key: '', title: "", cellTemplate: this.cart,searchEnabled:false },
        { key: 'ItemCode', title: "Codigo", cellTemplate: this.frmItemCode },
        { key: 'U_cod_equiva', title: "Cod Equ" },
        { key: 'ItemName', title: 'Descripción' },
        { key: 'ItmsGrpNam', title: 'Categoria' },
        { key: 'OnHand', title: 'En stock' },
        { key: 'IsCommited', title: 'NV' },
        { key: 'OnOrder', title: 'Por llegar' },
        { key: 'Available', title: 'Disponible' },
        { key: 'U_Ubicacion', title: 'Ubicación' },
        { key: 'U_Ubica2', title: 'Ubicación 2' },
      ];
     }

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
   };

    var data:any = await this._api.getQueryAll();
    var query:string = "";

    await data.objeto.forEach(element => {
      // if (element.NOMBRE == "stockComercial") {
        if(element.NOMBRE == "stockComercialCLP") { //stockComercial
        query = element.CONSULTA;
      }
    });

    this.data = await this._api.GetNotFileQuery(query);
    this.dataCopia = this.data.objeto;

    this.data.objeto.forEach(item => {
      item.PriceBruto = item.Price * 1.19;
    });
    // console.log("PRECIO CLP: "+ this.dataCopia[120].Price);
    console.log(data);
    console.log(this.dataCopia);
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "listaPrecios") {
        query = element.CONSULTA;
      }
    });
    var datos:any = await this._api.GetNotFileQuery(query);
    this.OPLN = datos.objeto;



    let collator = new Intl.Collator()
    var distinct = this.dataCopia
                 .map(item => item.ItmsGrpNam)
                 .filter((value, index, self) => self.indexOf(value) === index);
    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b));
    this.listaCategorias = distinct;

    distinct = this.dataCopia
                 .map(item => item.U_IND_CATEGORIA2)
                 .filter((value, index, self) => self.indexOf(value) === index);
    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b));
    this.listaFamilia = distinct;
    distinct = this.dataCopia
                 .map(item => item.U_IND_CATEGORIA3)
                 .filter((value, index, self) => self.indexOf(value) === index);

    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b)); //error posible acá
    this.listaSubFamilia = distinct;


    this.data = [...this.dataCopia];

    //console.log(this.data);
    if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

    Swal.close();
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
  AbrirDetalleItem(item)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(StockComercialDetalleItemBodegaComponent, ngbModalOptions);
    modalRef.componentInstance.ItemCode = item.ItemCode;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {


    }, (reason) => {
      //this.editar(item);
    });
  }

  limpiar()
  {
    this.data = [...this.dataCopia];
    //this.listaPrecio = 1;
    this.categoriaSeleccionada = "";
    this.familiaSeleccionada = "";
    this.subFamiliaSeleccionada = "";
  }

  filtro()
  {
    this.data = [...this.dataCopia];
    //ItmsGrpNam              categoriaSeleccionada
    //U_IND_CATEGORIA2        familiaSeleccionada
    //U_IND_CATEGORIA3        subFamiliaSeleccionada
    if (this.categoriaSeleccionada) {
      this.data = this.data.filter((_) => _.ItmsGrpNam == this.categoriaSeleccionada);
    }
    if (this.familiaSeleccionada) {
      this.data = this.data.filter((_) => _.U_IND_CATEGORIA2 == this.familiaSeleccionada);
    }
    if (this.subFamiliaSeleccionada) {
      this.data = this.data.filter((_) => _.U_IND_CATEGORIA3 == this.subFamiliaSeleccionada);
    }
  }
  async cambiaPrecio()
  {
    Swal.fire({
        title: 'Espere por favor!',
        html: 'Se esta cargando lista de precio',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading()
        }
    });
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "stockComercialCLP") { //stockComercial
        query = element.CONSULTA;
      }
    });
    query = query.replace("[PriceList] = 1", "[PriceList] = " + this.listaPrecio);
    console.log(query);
    var datos:any = await this._api.GetNotFileQuery(query);
    this.data = datos.objeto;
    this.dataCopia = [...this.data];
    this.filtro();
    Swal.close();

  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.data);
  }

}
