
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titulo}}</h4>

    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

      <div class="row">
        <label>RUT</label>
        <input type="text" [(ngModel)]="data.RUT" name="RUT" class="form-control">
    </div>
    <div class="row">

        <label>Nombre</label>
        <input type="text" [(ngModel)]="data.NOMBRE" name="NOMBRE" class="form-control">
    </div>
    <div class="row">

        <label>Correo</label>
        <input type="text" [(ngModel)]="data.CORREO" name="CORREO" class="form-control">
    </div>
    <div class="row">
      <label>Cargo</label>
      <input type="text" [(ngModel)]="data.CARGO" name="CARGO" class="form-control">
    </div>
    <div class="row">
       <label>Activo</label>
       <input type="checkbox" [(ngModel)]="data.ACTIVO" name="ACTIVO" class="form-control">
    </div>


    <div class="row">



        <label for="exampleInputFile">Foto</label>
        <img [src]="data.IMAGE_LITTLE" class="img-fluid" />
        {{imageError}}
        <div class="input-group">
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="exampleInputFile" (change)="fileChangeEvent($event)">
            <label class="custom-file-label" for="exampleInputFile">Seleccionar imagen</label>
          </div>

        </div>





              <a class="btn btn-danger" (click)="removeImage()" *ngIf="isImageSaved"><i class='fa fa-trash'></i></a>








  </div>


    <div class="form-group">
        <label>Perfil</label>
        <select [(ngModel)]="data.PERFIL" name="PERFIL" class="form-control">
          <option [ngValue]="null" disabled>Selecciona perfil</option>
          <option *ngFor="let perfil of perfiles" [ngValue]="perfil._id">{{perfil.NOMBRE}}</option>
        </select>

    </div>

    <div class="form-group">
      <label>Vendedor</label>
        <select [(ngModel)]="data.VENDEDOR" name="VENDEDOR" class="form-control">
          <option [ngValue]="null" disabled>Selecciona vendedor</option>
          <option *ngFor="let vendedor of vendedores" [ngValue]="vendedor.SlpCode">{{vendedor.SlpName}}</option>
        </select>


    </div>

    <div class="form-group">
        <label>Color</label>

        <input type="color" [(ngModel)]="data.COLOR" name="COLOR" class="form-control">
    </div>
    <div class="form-group">

        Admin
        <input type="checkbox" [(ngModel)]="data.ADMIN" name="ADMIN" class="form-control">

    </div>

    <div class="form-group">
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong" style="background: #96D23A;color: #343A40;border: #96D23A;">
        Restablecer contraseña
      </button>

    </div>

        <!-- Modal -->
    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Actualizar contraseña</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="text" [(ngModel)]="data.PASSWORDNEW">
          </div>


          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button (click)="recuperarPass()" type="button" class="btn btn-primary" data-dismiss="modal" style="background: #96D23A;color: #343A40;border: #96D23A;">Actualizar</button>
          </div>
        </div>
      </div>
    </div>





    <div class="form-group">
       <!--<input (click)="guardar();" class="btn btn-block btn-primary" value="Ingresar" />-->

    </div>


  </div>
  <div class="modal-footer">
    <!--<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>-->

    <button (click)="guardar()" type="button" class="btn btn-primary w-100">{{txtButton}}</button>
  </div>
