import { Component, OnInit, OnDestroy, Input,  EventEmitter} from '@angular/core';
import {IntranetoldService} from './../../../api/intranetold.service'
import {IntranetNewService} from "./../../../api/intranet-new.service"
import {variableGlobal} from './../../../global.enum';
import { NgxIndexedDBService,  } from 'ngx-indexed-db';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, } from "@angular/material/dialog";
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-perfilEditAdd',
  templateUrl: './addEdit.html',
  styleUrls: ['./perfil.component.sass']
})

export class PerfilAddEditComponent implements OnInit {
  @Input() anyDataForm: any;
    constructor(
      public activeModal: NgbActiveModal,
      private _api:IntranetoldService,
      private _api2:IntranetNewService,
      private _db:NgxIndexedDBService
      ) { 

        
      }

    data:any = {};
    menu:any = [];
    titulo:string = "";
    txtButton:string = "Actualizar";
    ngOnInit(): void {
        //console.log(this.usuario);
        //console.log(this.usuario.RUT);  
        if (this.titulo.toUpperCase().includes("NUEVO")) {
          this.txtButton = "Agregar";
        }

        this.getMenu();
    }

    async getMenu()
    {
      if (!this.titulo.toUpperCase().includes("NUEVO")) {
        
      
          console.log("obteniendo login base interna");
          let tx = this._db.createObjectStore

          var logins:any = await this._db.getAll(variableGlobal.DB_TABLE_GENERAL).toPromise();
          var login = logins.find(a => a.id == variableGlobal.DB_TABLE_GENERAL_id_login);
          console.log(login)
          var objeto = {
            "token": login.datos.login.TOKEN,
            "perfil": this.data._id,
          }
          console.log(objeto);
          var menu:any = await this._api2.ObtieneMenuAccesoProcesadoAdmin(objeto);
          console.log(menu);
          menu = menu.objeto;
          this.menu = menu;
          console.log(menu); 
      }
    }
    modalReference: NgbModalRef;
    cerrar() {
      this.activeModal.close();
        //this.modalReference.close(this.usuario);
    }

    async Actualizar()
    {
      var datos:any = await this._api.AddEditPerfil(this.data);
  

      
      if (datos.estado) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: datos.mensaje,
          showConfirmButton: false,
          timer: 1500
        });
        this.cerrar();
      }
      else
      {
        Swal.fire(datos.mensaje, '', 'error');
      }
    }

    async actualizaMenuAcceso(item, event)
    {
      var checked = event.target.checked;
      item.MenuAccesso = checked;

      var MENU_ACCESO = {
        "_id": item._idMenuAccesso,
        "RUT": "",
        "ID_MENU": item._id,
        "ACCESO": item.MenuAccesso,
        "PERFIL": this.data._id
      }

      var datos:any = await this._api.AddEditMenuAcceso(MENU_ACCESO);
      //console.log(datos);
      if (datos.estado) {
        item._idMenuAccesso = datos.objeto._id;
      }
      else{
        Swal.fire(datos.mensaje, '', 'error');
      }
    }


}