<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titulo}}</h4>

    <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>-->
  </div>
  <div class="modal-body">
     

      <div class="form-group">
        <label>Nombre</label>
        <input type="text" [(ngModel)]="data.NOMBRE" name="NOMBRE" class="form-control">
    </div>
    <div class="form-group">
    
        <label>URL Default</label>
        <input type="text" [(ngModel)]="data.URL_DEFAULT" name="URL_DEFAULT" class="form-control">
    </div>

    <button (click)="Actualizar()" type="button" class="btn btn-primary w-100">{{txtButton}}</button>

  
    <div class="col-12">
        <div class="card collapsed-card" *ngFor="let item of menu">
            <div class="card-header ">


                <h3 class="card-title">
                    <i class="{{item.ICONO}}"></i>  {{item.MENU}}
                    <input type="checkbox" (click)="actualizaMenuAcceso(item, $event)"  name="MenuAccesso" id="MenuAccesso" [checked]="item.MenuAccesso">
                </h3>

                <div class="card-tools">

                    <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip">
                        <i class="fas fa-plus"></i>
                    </button>

                </div>
            </div>


            <div class="card-body" style="display: none;">
                <p *ngFor="let item2 of item.HIJO">
                    <i class="{{item2.ICONO}}"></i> {{item2.MENU}} <input (click)="actualizaMenuAcceso(item2, $event)" name="MenuAccesso" id="MenuAccesso" type="checkbox" [checked]="item2.MenuAccesso">
                </p>
            </div>

        </div>
   



</div>




  </div>
  <div class="modal-footer">
    <!--<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>-->

    <button (click)="cerrar()" type="button" class="btn btn-success">Cerrar</button>
  </div>