<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detalle cliente</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class="col-md-12">

            <div class="row">
                <div class="col-12 col-sm-12">
                    <div class="card card-tabs">
                        <div class="card-header p-0 pt-1">
                            <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#Tab1" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Saldo</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" href="#Tab2" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Cuentas pendientes</a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content" id="custom-tabs-one-tabContent">
                                <div class="tab-pane fade show active" id="Tab1" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                                    <ngx-table #table [configuration]="configurationSaldos"
                                    [data]="dataSaldos"
                                    [columns]="columnsSaldos"
                                ></ngx-table>
      
                                <ng-template #frmRefDate let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.RefDate | date:'dd/MM/yyyy'}}</div>
                                </ng-template>
                                
                                <ng-template #frmDebit let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.Debit | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frmCredit let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.Crebit | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frmBalDueDeb let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.BalDueDeb | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frmBalDueCred let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.BalDueCred | iNDCLP}}</div>
                                </ng-template>
                                </div>
                                
                                <div class="tab-pane fade" id="Tab2" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
                                    <ngx-table #table2 [configuration]="configurationVencidos" [data]="dataVencidos" [columns]="columnsVencidos"
                                ></ngx-table>
                                <ng-template #frmFechaemision let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.Fechaemision | date:'dd/MM/yyyy'}}</div>
                                </ng-template>
                                
                                <ng-template #frmTotalFactura let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.TotalFactura | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frm15dias let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.V115 | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frm31dias let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.V1_31 | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frm61dias let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.V1_61 | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frm91dias let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.V1_91 | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frm121dias let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.V1_121 | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frm151dias let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.v151 | iNDCLP}}</div>
                                </ng-template>
                                <ng-template #frm181dias let-row let-rowIndex="rowIndex">                                    
                                    <div>{{row.VMasDe180 | iNDCLP}}</div>
                                </ng-template>

                                
                                </div>



                            </div>
                        </div>
                        <!-- /.card -->
                    </div>
                </div>

            </div>
        </div>
    </div>




  </div>
  <div class="modal-footer">
    <!--<button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>-->
  </div> 
