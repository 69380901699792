import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from '../../../api/intranetold.service'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {DocumentoMarketingDetalle, DocumentoMarting} from "./../../../model/ventaModel"
import { variableGlobal } from '../../../global.enum';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StockComercialDetalleItemBodegaComponent } from './../stock-comercial/detalleArticuloBodega';
import { abreTablaGenericoComponent } from '../../app/query-mongo/abreTablaGenerico';


@Component({
  selector: 'app-selectedItemCode',
  templateUrl: './listaArticulo.html',
  styleUrls: ['./venta.component.sass']
})
export class listaArticuloComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService,
    private modalService: NgbModal,
  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  public configuration: Config;
  public columns: Columns[];
  PriceList = 1;
  Encabezado:DocumentoMarting;
  tipoCambio:number;
  Detalle:DocumentoMarketingDetalle;
  condicionListaMateriales:string = "ListaMateriales"
  data:any;
  USD=0;
  EUR=0;

  async ngOnInit(): Promise<void> {
    await this.cargaInicial();
    await this.CargarMonedas();

    console.log("DATA",this.data)


  }
  @ViewChild('frmItemCode', { static: true }) frmItemCode: TemplateRef<any>;
  @ViewChild('frmPrice', { static: true }) frmPrice: TemplateRef<any>;
  @ViewChild('frmPriceBruto', { static: true }) frmPriceBruto: TemplateRef<any>;
  @ViewChild('frmSerie', { static: true }) frmSerie: TemplateRef<any>;
  @ViewChild('frmLote', { static: true }) frmLote: TemplateRef<any>;
  @ViewChild('frmPriceOther', { static: true }) frmPriceOther: TemplateRef<any>;

  async CargarMonedas(){
    this.USD = await this.GetRates("USD");
    this.EUR = await this.GetRates("EUR");
  }

  async cargaInicial()
  {
    this.columns = [
      { key: 'ItemCode', title: "Artículo", cellTemplate: this.frmItemCode},
      { key: 'U_cod_equiva', title: "Cod Equ" },
      { key: 'ItemName', title: 'Descripción' },
      { key: 'U_Ubicacion',title:'Ubicación Local'},
      { key: 'U_cod_equiva',title:'Código Equivalente'},
      { key: 'ItmsGrpNam', title: 'Familia' },
      { key: 'OnHand', title: 'Stock' },
      { key: 'U_Ubicacion', title: 'Ubicación' },
      { key: 'U_Ubica2', title: 'Ubicación 2' },
      { key: 'Price', title: 'Precio Neto', cellTemplate: this.frmPrice },
      { key: 'PriceBruto', title: 'Precio Bruto', cellTemplate: this.frmPriceBruto },


    ];
    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: false,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: true,
      orderEnabled: true,
      rowReorder:false,
      columnReorder:false,
      fixedColumnWidth: false,
      selectRow:false,

      paginationEnabled:true,
      //radio:true
      //infiniteScroll:true,
      //infiniteScrollThrottleTime: 10,
      //rows:10,

   };


    //cargaListaMateriales
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    console.log("DATA 1",data)
    await data.objeto.forEach(element => {
      if (element.NOMBRE == this.condicionListaMateriales) {
        query = element.CONSULTA;
      }
    });

    query = query.replace("REEMPLAZA", this.PriceList.toString());
    if (this.Encabezado.Currency != "CLP") {
      query = query.replace("T2.[Price]", "T2.[Price], '" + this.Encabezado.Currency + "' AS [CurrencyOther], ROUND(T2.[Price] / " + this.tipoCambio + ", " + variableGlobal.DECIMALES_OTRA_MONEDA + ") AS [PriceOther]");

      if(this.Encabezado.ObjType=="540000006"|| this.Encabezado.ObjType=="22"){
        query = query.replace("T0.[SellItem] = 'Y'","T0.[PrchseItem] = 'Y'")
      }
    }

    console.log("QUERY",query)


    data = await this._api.GetNotFileQuery(query);
    this.data = data.objeto;

    this.data.forEach(item => {
      item.PriceBruto = item.Price * 1.19;
    });
    console.log("DATA ARTICULOS",this.data)

    if(variableGlobal.ESTILO_EASY_TABLE)
    this.setClass(variableGlobal.ESTILO_EASY_TABLE);
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  cerrar() {
    this.activeModal.close();
  }
  eventEmitted($event): void {
    //this.clicked = JSON.stringify($event);
    //console.log('$event', $event);
    var data:any = $event.value.row;
    // || $event.event == "onClick"
    if ($event.event == "onDoubleClick") {
      //console.log($event);
      console.log("PRINT");
      console.log(data);
      this.Detalle.LineNum = -1;
      this.Detalle.VATLiable = data.VATLiable;
      this.Detalle.ItemCode = data.ItemCode;
      this.Detalle.Dscription = data.ItemName;
      this.Detalle.Factor1 = data.SalFactor1;
      this.Detalle.Factor2 = data.SalFactor2;

      if(!data.Price)
      {
        data.Price=0;
      }

      this.Detalle.Price = data.Price.toFixed(variableGlobal.DECIMALES_CLP);
      this.Detalle.PriceBruto = data.PriceBruto.toFixed(variableGlobal.DECIMALES_CLP);
      this.Detalle.Disc = 0;
      this.Detalle.OnHand = data.OnHand;
      this.Detalle.WhsCode = data.DfltWH;
      this.Detalle.Currency = data.Currency
      this.Detalle.Quantity = data.SalFactor2;
      this.Detalle.ManSerNum = data.ManSerNum;
      this.Detalle.ManBtchNum = data.ManBtchNum;
      this.Detalle.PrecioOG = data.Price;
      this.Detalle.CurrencyOG = data.Currency;
      this.Detalle.SalUnitMsr = data.SalUnitMsr;
      this.Detalle.BuyUnitMsr = data.BuyUnitMsr;
      this.Detalle.Quantity = 1;
      this.Detalle.ItmsGrpNam = data.ItmsGrpNam;
      this.Detalle.U_Ubicacion = data.U_Ubicacion;

      if (this.Encabezado.Currency != "CLP") {
        if(this.Encabezado.Currency != data.Currency)
        {
          if(!data.PriceOther){
            data.PriceOther=0;
          }
          this.Detalle.Price = data.PriceOther.toFixed(variableGlobal.DECIMALES_OTRA_MONEDA);
          this.Detalle.Currency = this.Encabezado.Currency;
        }else{
          this.Detalle.Price = data.Price.toFixed(variableGlobal.DECIMALES_OTRA_MONEDA);
        }

      }
      if(data.Currency!="CLP" && this.Encabezado.Currency=="CLP"){

        if(data.Currency == "USD"){
          this.Detalle.Price=data.Price * this.USD;
        }


      }
      console.log("Salio de la cosa rara");
      if (this.Encabezado.ObjType == "17") {
        if (this.Detalle.ManBtchNum == "Y") {
          this.Detalle.ocultaLote = false;
        }
        if (this.Detalle.ManSerNum == "Y") {
          this.Detalle.ocultaSerie = false;
        }

        //console.log(this.Detalle);
      }

      console.log(this.Detalle);
    }
    if ($event.event == "onDoubleClick") {
      //console.log(data);
      //console.log(this.Detalle.Factor1, this.Detalle.Factor2)
      this.Seleccionar();
    }

  }
  Seleccionar()
  {
    this.activeModal.close(this.Detalle);
  }

  AbrirDetalleItem(item)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(StockComercialDetalleItemBodegaComponent, ngbModalOptions);
    modalRef.componentInstance.ItemCode = item.ItemCode;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {


    }, (reason) => {
      //this.editar(item);
    });
  }

  async abrirItemLote(item) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };

    var queryConsulta: string;
    queryConsulta = await this.obtieneQueryMongo("VistaLotesBodega");

    queryConsulta = queryConsulta.replace("INPUTARTICULO", item.ItemCode);
    queryConsulta = queryConsulta.replace("INPUTBODEGA", item.WhsCode);

    const modalRef = this.modalService.open(abreTablaGenericoComponent, ngbModalOptions);
    modalRef.componentInstance.query = queryConsulta;
    modalRef.componentInstance.titulo = "Detalle lotes de articulo";
    modalRef.result.then();

  }

  async GetRates(Currency){
    var oRate=-1;

    if (Currency != "CLP") {
      var data:any = await this._api.getQueryAll();
      var query:string;
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "tipoCambioTodo") {
          query = element.CONSULTA;
        }
      });

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      var fechaStr:string = yyyy + "-" + mm + "-" + dd;
      //var fechaStr:string = "2023-09-10";
      query = query + " WHERE [RateDate] = '" + fechaStr + "' AND [Currency] = '" + Currency + "'";
      var data:any = await this._api.GetNotFileQuery(query);


      if (data.objeto.length > 0) {
        var Rate = data.objeto[0].Rate;
        if (Rate > 0) {
          oRate = Rate;
        }
      }
      if(oRate<0){
        oRate=1;
      }
    }
    else
    {
      oRate=1;
    }

    return oRate;
  }

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

}
