<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Lista articulos</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
     <span>Favor hacer doble click para seleccionar artículo</span>

    <ngx-table #table [configuration]="configuration"
        [data]="data"
        [columns]="columns"
        (event)="eventEmitted($event)"></ngx-table>
        <ng-template #frmItemCode let-row let-rowIndex="rowIndex">
            <button class="btn btn-primary" (click)="AbrirDetalleItem(row)">{{row.ItemCode}}</button>
        </ng-template>
        <ng-template #frmPrice let-row let-rowIndex="rowIndex">
          <div *ngIf="row.Currency != 'CLP'">{{row.Price | currency: row.Currency}}</div>
          <div *ngIf="row.Currency == 'CLP'">{{row.Price | iNDCLP}}</div>
        </ng-template>
        <ng-template #frmPriceBruto let-row let-rowIndex="rowIndex">
          <div *ngIf="row.Currency != 'CLP'">{{row.PriceBruto | currency: row.Currency}}</div>
          <div *ngIf="row.Currency == 'CLP'">{{row.PriceBruto | iNDCLP}}</div>
        </ng-template>
        <ng-template #frmSerie let-row let-rowIndex="rowIndex">
          <div *ngIf="row.ManSerNum == 'N'">No</div>
          <div *ngIf="row.ManSerNum == 'Y'">Si</div>
        </ng-template>
        <ng-template #frmLote let-row let-rowIndex="rowIndex">
          <div *ngIf="row.ManBtchNum == 'N'">No</div>
          <div *ngIf="row.ManBtchNum == 'Y'">Si</div>
        </ng-template>

        <ng-template #frmPriceOther let-row let-rowIndex="rowIndex">
          <div>{{row.PriceOther | currency: Encabezado.currency}}</div>
        </ng-template>


  </div>
  <div class="modal-footer">
    <!--<button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>-->
  </div>
