import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { IntranetoldService } from './../../../api/intranetold.service';
import { DocumentoMarting, DocumentoMarketingDetalle } from '../../../model/ventaModel'
import { listaArticuloComponent } from "./listaArticulo"
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import { variableGlobal } from 'src/app/global.enum';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { listaClienteComponent } from './listaCliente';
import { listaDireccionComponent } from './listaDireccion';
import { listaLoteComponent } from './listaLote';
import { listaSerieComponent } from './listaSerie';
import { SapService } from './../../../api/sap.service';
import { CuentasSocioComponent } from './cuentas-socio/cuentas-socio.component';
import { abreTablaGenericoComponent } from '../../app/query-mongo/abreTablaGenerico';
import { INDESADService } from 'src/app/Business/INDESAD.service';
import { MongoService } from 'src/app/api/mongo.service';
import { DOCUMENTO_MARKETING_PRELIMINAL, MODELO_APROBACION, aprobadores, SolicitarAprobacion } from 'src/app/model/Mongo';
import { ModeloAprobacionCRMComponent } from './ModeloAprobacion/ModeloAprobacion.component';
import { IndesadService } from 'src/app/api/indesad.service';
import { element } from 'protractor';
import { SocioNegocioComponent } from '../socio-negocio/socio-negocio.component';
import { DATE } from '@amcharts/amcharts4/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-venta',
  templateUrl: './venta.component.html',
  styleUrls: ['./venta.component.sass']
})
export class VentaComponent implements OnInit {

  constructor(
    private _activeRoute: ActivatedRoute,
    private _api: IntranetoldService,
    private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,
    private _apiSap: SapService,
    private _apiIndesad: IndesadService,
    private _apiMongo: MongoService,

  ) {

  }
  @ViewChild('table', { static: true }) table: APIDefinition;
  myControl = new FormControl('');
  date = new Date();
  data: DocumentoMarting = {

    OCBase64Nombre:"",
    OCBase64Formato:"",
    OCBase64:"",
    UserFields: [

    ],
    Draft: false,
    SubObjType: null,
    DocEntry: -1,
    DocNum: -1,
    Rate: 1,
    CardCode: null,
    CardName: null,
    Currency: "CLP",
    NumAtCard: null,
    Series: -1,
    DocType: "I",
    ShipToCode: null,
    PayToCode: null,
    Address: null,
    Address2: null,
    GroupNum: null,
    GroupCode: null,
    TrnspCode: null,
    TaxDate: null,
    DocDueDate: null,
    DocDate: null,
    ObjType: null,
    SlpCode: -1,
    OwnerCode: null,
    Comments: "",
    GrossProfit: 0,
    DiscPrcnt: 0,
    DiscTotal: 0,
    TotalExpns: 0,
    VatSum: 0,
    DocTotal: 0,
    DescuentoCampoUsuario: 10,
    Detalles: [],
    BillToDef: null,
    ShipToDef: null,
    ListaDePrecioSocio: null,
    ListaCUrrency: false,

    AddresList: {
      StreetS: null,
      StreetNoS: null,
      BlockS: null,
      CityS: null,
      ZipCodeS: null,
      CountyS: null,
      StateS: null,
      CountryS: null,
      BuildingS: null,
      Address2S: null,
      Address3S: null,
      GlbLocNumS: null,
      StreetB: null,
      StreetNoB: null,
      BlockB: null,
      CityB: null,
      ZipCodeB: null,
      CountyB: null,
      StateB: null,
      CountryB: null,
      BuildingB: null,
      Address2B: null,
      Address3B: null,
      GlbLocNumB: null,
    }
  };
  TaxCode = "IVA";
  TaxRate = 0;
  ObjType;
  DocEntry;
  Origen;
  titulo: string;
  creaActualiza: string;
  BodegaEditable: boolean;
  activaAgregarArticulo: boolean = false;
  usuario;
  listaMateriales: any;
  public configuration: Config;
  public columns: Columns[];
  columnsCopy: Columns[] = [];
  DESPACHO: any;
  FACTURA: any;

  OSTC: any;
  OHEM: any;
  OSLP: any;
  OCRN: any;
  OCTG: any;
  OSHP: any;
  OWHS: any;
  OCRD: any;
  IMPUESTOS: any;
  OCRDCopy: any;
  IND_Tipo_E: any;
  FILES: any;
  VATLiable: any;
  //checked:any = [];

  checked = new Set([]);
  @ViewChild('frmItemCode', { static: true }) frmItemCode: TemplateRef<any>;
  @ViewChild('frmDscription', { static: true }) frmDscription: TemplateRef<any>;
  @ViewChild('frmFactor1', { static: true }) frmFactor1: TemplateRef<any>;
  @ViewChild('frmFactor2', { static: true }) frmFactor2: TemplateRef<any>;
  @ViewChild('frmQuantity', { static: true }) frmQuantity: TemplateRef<any>;
  @ViewChild('frmSalUnitMsr', { static: true }) frmSalUnitMsr: TemplateRef<any>;
  @ViewChild('frmBuyUnitMsr', { static: true }) frmBuyUnitMsr: TemplateRef<any>;
  @ViewChild('frmPrice', { static: true }) frmPrice: TemplateRef<any>;
  @ViewChild('frmPriceBruto', { static: true }) frmPriceBruto: TemplateRef<any>;
  @ViewChild('frmDisc', { static: true }) frmDisc: TemplateRef<any>;
  @ViewChild('frmPriceAfeterDisc', { static: true }) frmPriceAfeterDisc: TemplateRef<any>;
  @ViewChild('frmLineTotal', { static: true }) frmLineTotal: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  @ViewChild('frmWhsCode', { static: true }) frmWhsCode: TemplateRef<any>;
  @ViewChild('frmEstadoWMS', { static: true }) frmEstadoWMS: TemplateRef<any>;
  tipoCambio: number = 1;

  objetoSAP: any;

  private selectedFile: File;


  LoadOC(event) {

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);


    reader.onload = () => {

      const encodedFile = reader.result?.toString().split(',')[1];
      this.data.OCBase64 = encodedFile;

    }

    this.data.OCBase64Nombre = file.name;
    //Imágenes:
    if (file.type == "image/jpeg") {
      this.data.OCBase64Formato = ".jpg";
    }
    if (file.type == "image/png") {
      this.data.OCBase64Formato = ".png";
    }
    if (file.type == "image/gif") {
      this.data.OCBase64Formato = ".gif";
    }
    if (file.type == "image/bmp") {
      this.data.OCBase64Formato = ".bmp";
    }
    if (file.type == "image/webp") {
      this.data.OCBase64Formato = ".webp";
    }

  //Documentos:
    if (file.type == "application/pdf") {
      this.data.OCBase64Formato = ".pdf";
    }
    if (file.type == "application/msword") {
      this.data.OCBase64Formato = ".doc";
    }
    if (file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      this.data.OCBase64Formato = ".docx";
    }
    if (file.type == "application/vnd.ms-powerpoint") {
      this.data.OCBase64Formato = ".ppt";
    }
    if (file.type == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
      this.data.OCBase64Formato = ".pptx";
    }

    //Hojas de cálculo:

    if (file.type == "application/vnd.ms-excel") {
      this.data.OCBase64Formato = ".xls";
    }
    if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.data.OCBase64Formato = ".xlsx";
    }


  }

  ngOnInit(): void {

    if (environment.RUTA_API.includes("peru")) {
      this.data.Currency = "S/";
      this.TaxCode = "IGV";
    }



    this.BodegaEditable = true;

    //
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      item => {
        var itemx: any = item;
        this.usuario = itemx.datos.login;
      }
    );

    this.columnsCopy = [
      { key: 'ItemCode', title: "Articulo", cellTemplate: this.frmItemCode },
      { key: 'Dscription', title: 'Descripción', cellTemplate: this.frmDscription },
      { key: 'Factor1', title: 'Factor1', cellTemplate: this.frmFactor1 },
      { key: 'Factor2', title: 'Factor2', cellTemplate: this.frmFactor2 },
      { key: 'Quantity', title: 'Cantidad', cellTemplate: this.frmQuantity },
      { key: 'SalUnitMsr', title: 'U. Medida Venta', cellTemplate: this.frmSalUnitMsr },
      { key: 'BuyUnitMsr', title: 'U. Medida Compra', cellTemplate: this.frmBuyUnitMsr },
      { key: 'WhsCode', title: 'Bodega', cellTemplate: this.frmWhsCode },
      { key: 'Price', title: 'Precio', cellTemplate: this.frmPrice },
      { key: 'PriceBruto', title: 'Precio Bruto', cellTemplate: this.frmPriceBruto },
      { key: 'ItmsGrpNam', title: 'Familia' },
      { key: 'U_Ubicacion', title: 'Ubicación' },
      { key: 'Disc', title: 'Dsc.', cellTemplate: this.frmDisc },
      { key: 'PriceAfeterDisc', title: 'Pre.Un.Trs.Des', cellTemplate: this.frmPriceAfeterDisc },
      { key: 'LineTotal', title: 'T.Net.', cellTemplate: this.frmLineTotal },
      { key: '', title: 'Acción', cellTemplate: this.frmAccion },
    ];
    this.columns = this.columnsCopy;

    this.columnsCopy.forEach(element => {
      this.checked.add(element.key);
    });

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: false,
      detailsTemplate: true,
      paginationRangeEnabled: false,
      paginationEnabled: false,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: false,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: false,
      rows: 9999
    };

    this.cargaDatosIniciales();

    //
  }
  @ViewChild('CardCode') CardCode!: ElementRef<HTMLInputElement>;
  async validarSocioSeleccionado() {
    var socioEncontrado: boolean = false;
    await this.OCRD.forEach(async element => {
      if (this.data.CardCode == element.CardCode) {
        socioEncontrado = true;
      }
    });
    if (!socioEncontrado) {
      this.CardCode.nativeElement.focus();
      this.data.CardCode = "";
      Swal.fire("Mensaje", "Debes ingresar un cliente correcto", "error");
    }
    else {
      this.cargaDAtosAlTenerSocio();
    }

  }

  filtraSocio() {
    this.OCRDCopy = [];
    //
    this.OCRD.forEach(element => {
      if (element.CardName.toUpperCase().includes(this.data.CardCode.toUpperCase())) {
        //
        this.OCRDCopy.push(element);
      }
    });
  }

  async cambioTipoMoneda(desdeSelect: boolean = false) {

    var monedaLocal = "CLP";
    if (environment.RUTA_API.includes("peru")) {
      monedaLocal = "S/";
    }

    if (this.data.Currency != monedaLocal) {
      var data: any = await this._api.getQueryAll();
      var query: string;
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "tipoCambioTodo") {
          query = element.CONSULTA;
        }
      });

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      var fechaStr: string = yyyy + "-" + mm + "-" + dd;
      //var fechaStr:string = "2023-09-10";
      query = query + " WHERE [RateDate] = '" + fechaStr + "' AND [Currency] = '" + this.data.Currency + "'";
      var data: any = await this._api.GetNotFileQuery(query);
      if (data.objeto.length > 0) {
        var Rate = data.objeto[0].Rate;
        if (Rate > 0) {
          this.tipoCambio = Rate;
        }
        else {
          Swal.fire("Mensaje", "Tipo de cambio " + this.data.Currency + " no esta cargado en SAP, favor solicitar carga de este", "error");
        }
      }
      else {
        Swal.fire("Mensaje", "Tipo de cambio " + this.data.Currency + " no esta cargado en SAP, favor solicitar carga de este", "error");
      }

      //

    }
    else {
      this.tipoCambio = 1;
    }


    if (desdeSelect) {
      //cambiar cositas

      var promesa = new Promise((resolve, rejects) => {

        this.data.Detalles.forEach(async element => {


          if (this.data.Currency == element.CurrencyOG) {
            element.Currency = this.data.Currency;
            element.Price = element.PrecioOG;
            element.Disc = 0;
            element.PriceAfeterDisc = element.Price;
          } else {
            let currency = this.data.Detalles[0].Currency;
            if (this.data.Currency == "CLP") {
              var elementCurrency = element.Currency;

              let oRate = await (this.GetRates(elementCurrency));

              element.Price = Math.round(element.Price * oRate);
              element.Currency = this.data.Currency;
              element.PriceAfeterDisc = element.Price;

            } else {
              if (element.Currency != 'CLP') {
                let oRate = await (this.GetRates(element.Currency));
                element.Price = element.Price * oRate;
              }
              element.Price = element.Price / this.tipoCambio;
              element.PriceAfeterDisc = element.Price;
              element.Currency = this.data.Currency;

            }
          }
          //
        });
      })

      promesa.then(() => {
        this.actualizaDetalleAll();
      });
      //
      //

    }

  }

  async GetRates(Currency) {
    var oRate = -1;

    if (Currency != "CLP") {
      var data: any = await this._api.getQueryAll();
      var query: string;
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "tipoCambioTodo") {
          query = element.CONSULTA;
        }
      });

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      var fechaStr: string = yyyy + "-" + mm + "-" + dd;
      //var fechaStr:string = "2023-09-10";
      query = query + " WHERE [RateDate] = '" + fechaStr + "' AND [Currency] = '" + Currency + "'";
      var data: any = await this._api.GetNotFileQuery(query);


      if (data.objeto.length > 0) {
        var Rate = data.objeto[0].Rate;
        if (Rate > 0) {
          oRate = Rate;
        }
      }
      if (oRate < 0) {
        oRate = 1;
      }
    }
    else {
      oRate = 1;
    }

    return oRate;
  }

  // TOMA URL Y DETERMINA SI CREA O ACTUALIZA
  async cargaDocumentoDesdeSAP() {
    console.log("Actualiza crea documento por url");
    if (this.DocEntry) {
      if (this.DocEntry > 0) {
        this.data.DocEntry = this.DocEntry;
        //
        var datos: any = await this._apiSap.ObtenerDocumentoMarketingLITE(this.data);
        console.log("Documento obtenido", datos);

        if (datos.estado) {
          this.creaActualiza = "Actualizar";
          this.data = datos.resultado;

          this.cargaDAtosAlTenerSocio();
          if (this.Origen) {
            if (this.Origen == "NEW") {
              this.creaActualiza = "Crear";
              this.data.DocEntry = -1;
              this.DocEntry = -1;
            }
            else {
              var clon = { ...this.data };
              this.creaActualiza = "Crear";
              this.data.Detalles.forEach((element, rowindex) => {
                element.BaseEntry = clon.DocEntry,
                  element.BaseLine = clon.Detalles[rowindex].LineNum,
                  element.BaseType = clon.ObjType
              });
              this.data.DocEntry = -1;
              this.DocEntry = -1;
              this.data.ObjType = this.Origen;
              this.ObjType = this.Origen;
              this.data.Series = -1;

              if (this.Origen == "17" || this.Origen == "540000006") {
                this.data.Detalles.forEach(element => {
                  if (element.ManBtchNum == "Y") {
                    element.ocultaLote = false;
                  }
                  if (element.ManSerNum == "Y") {
                    element.ocultaSerie = false;
                  }
                });
              }


              //
            }
          }

          //
        }
        else {
          Swal.fire("Mensaje", datos.mensaje, "error");
        }
      }

    }
    else {
      var datos: any = await this._apiSap.ObtenerDocumentoMarketingLITE(this.data);

      //

    }

    this.asignarVendedorLogueado();

    var filtro = this.objetoSAP.filter((a) => a.ObjType === this.ObjType);
    this.titulo = filtro[0].DESCRIPCION;

    //console.log("filtro", this.ObjType, filtro);


    /*
    if (this.ObjType.toString() == "23") {
      this.titulo = "Cotización"
    }
    else if (this.ObjType.toString() == "17") {
      this.titulo = "Nota de venta"
    }
    else if (this.ObjType.toString() == "540000006") {
      this.titulo = "Pedido interno"

    }
    else if (this.ObjType.toString() == "22") {
      this.titulo = "Pedido en transito"
    }
    else {
      this.titulo = "No controlado";
    }*/

    //cuando es crear documento
    if (this.creaActualiza == "Crear") {
      var data: any = await this._api.getQueryAll();
      var query: string;
      await data.objeto.forEach(element => {
        if (element.NOMBRE == "numeracionDocumentos") {
          query = element.CONSULTA;
        }
      });

      query = query + " WHERE [ObjectCode] = " + this.data.ObjType;

      var data: any = await this._api.GetNotFileQuery(query);
      this.data.DocNum = data.objeto[0].NextNumber;
      //
    }
  }
  cargaDatosDireccionDespacho(item) {
    //
    //
    this.DESPACHO.forEach(element => {
      if (element.Address == item) {
        this.cargaDatosDireccionDespacho2(element);
      }
    });
  }

  cargaDatosDireccionDespacho2(item) {
    //
    this.data.AddresList.CountryS = item.Country;
    this.data.AddresList.CityS = item.City;
    this.data.AddresList.CountyS = item.County;
    this.data.AddresList.StateS = item.State;
    this.data.AddresList.StreetS = item.Street;
    this.data.AddresList.ZipCodeS = item.ZipCode;


    var Street = this.data.AddresList.StreetS;
    var City = this.data.AddresList.CityS;
    var County = this.data.AddresList.CountyS;
    if (!Street) {
      Street = "";
    }
    if (!City) {
      City = "";
    }
    if (!County) {
      County = "";
    }

    this.data.Address = Street + ", " + City + ", " + County;
  }

  async abrirLoteSerie(item: DocumentoMarketingDetalle) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    var modalRef;
    if (item.ManSerNum == "Y") {
      modalRef = this.modalService.open(listaSerieComponent, ngbModalOptions);
    }
    if (item.ManBtchNum == "Y") {
      modalRef = this.modalService.open(listaLoteComponent, ngbModalOptions);
    }
    modalRef.componentInstance.data = this.data.Detalles;
    modalRef.componentInstance.ItemCode = item.ItemCode;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data: DocumentoMarketingDetalle[]) => {
      if (data) {

        this.data.Detalles = data;
        this.actualizaDetalleAll();
      }
    }, (reason) => {
      //this.editar(item);
    });

  }

  async abrirItemDetalle(item: DocumentoMarketingDetalle) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };


    var queryConsulta: string;

    if (this.ObjType == 23 || this.ObjType == 17) {
      queryConsulta = await this.obtieneQueryMongo("DocumentosVentaArticulo");//
    } else {
      queryConsulta = await this.obtieneQueryMongo("DocumentosCompraArticulo");
    }

    queryConsulta = queryConsulta.replace("INPUTARTICULO", item.ItemCode);
    queryConsulta = queryConsulta.replace("INPUTBODEGA", item.WhsCode);

    const modalRef = this.modalService.open(abreTablaGenericoComponent, ngbModalOptions);
    modalRef.componentInstance.query = queryConsulta;
    modalRef.componentInstance.titulo = "Detalle ventas de articulo";
    modalRef.result.then();

  }

  actualizaDetalleAll() {
    this.data.Detalles = [
      ...this.data.Detalles.map((obj, index) => {

        return { ...obj }

      }),
    ];
  }

  async abreDireccion(tipo) {


    var data = {
      "Street": "",
      "City": "",
      "ZipCode": "",
      "County": "",
      "State": "",
      "Country": "CL"
    }
    if (tipo == "DESPACHO") {
      data.Country = this.data.AddresList.CountryS;
      data.City = this.data.AddresList.CityS;
      data.County = this.data.AddresList.CountyS;
      data.State = this.data.AddresList.StateS;
      data.Street = this.data.AddresList.StreetS;
      data.ZipCode = this.data.AddresList.ZipCodeS;
    }
    else if (tipo == "FACTURA") {
      data.Country = this.data.AddresList.CountryB;
      data.City = this.data.AddresList.CityB;
      data.County = this.data.AddresList.CountyB;
      data.State = this.data.AddresList.StateB;
      data.Street = this.data.AddresList.StreetB;
      data.ZipCode = this.data.AddresList.ZipCodeB;
    }


    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "lg",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(listaDireccionComponent, ngbModalOptions);
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {
      if (data) {

        //
        //
        if (tipo == "DESPACHO") {
          this.data.AddresList.CountryS = data.Country;
          this.data.AddresList.CityS = data.City;
          this.data.AddresList.CountyS = data.County;
          this.data.AddresList.StateS = data.State;
          this.data.AddresList.StreetS = data.Street;
          this.data.AddresList.ZipCodeS = data.ZipCode;

          this.cargaDatosDireccionDespacho2(data);
        }
        else if (tipo == "FACTURA") {
          this.data.AddresList.CountryB = data.Country;
          this.data.AddresList.CityB = data.City;
          this.data.AddresList.CountyB = data.County;
          this.data.AddresList.StateB = data.State;
          this.data.AddresList.StreetB = data.Street;
          this.data.AddresList.ZipCodeB = data.ZipCode;
        }
        //
        this.data = { ...this.data };

      }
    }, (reason) => {
      //this.editar(item);
    });
  }

  ocultaColumna(name: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //
    this.checked.has(name) ? this.checked.delete(name) : this.checked.add(name);
    this.columns = this.columnsCopy.filter((column) => this.checked.has(column.key));
  }

  validaColumnaValidaInicio(key) {
    var respuesta: boolean = false;
    this.columns.forEach(element => {
      if (element.key == key) {
        respuesta = true;
      }
    });

    return respuesta;
  }

  async selecionarCliente() { //encargado de cargar los datos de los clientes al pinchar icono de lupa

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(listaClienteComponent, ngbModalOptions);
    modalRef.componentInstance.PriceList = this.data.ListaDePrecioSocio;
    modalRef.componentInstance.Encabezado = this.data;
    //modalRef.componentInstance.data = this.OCRD;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {
      if (data) {
        this.cargaDAtosAlTenerSocio();
      }
    }, (reason) => {
      //this.editar(item);
    });


  }

  async CargarDatosClienteCuentas() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };

    const modalRef = this.modalService.open(CuentasSocioComponent, ngbModalOptions);

    modalRef.componentInstance.CardCode = this.data.CardCode;
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(async (data) => {
      if (data) {

        //cargar datos ahroa que tengo cliente

      }
    }, (reason) => {
      //this.editar(item);
    });
  }





  HidePur() {
    if (this.ObjType.toString() == "540000006") {
      return true;
    } else {
      return false;
    }
  }


  async cargaDAtosAlTenerSocio() {


    /*
    if (!this.Origen) {
      this.OCRD.forEach(element => {
        if (element.CardCode == this.data.CardCode) {
          this.data.CardName = element.CardName;
          this.data.DescuentoCampoUsuario = element.U_Descuento;
          this.data.ShipToDef = element.ShipToDef;
          this.data.BillToDef = element.BillToDef;
          this.data.ListaDePrecioSocio = element.ListNum;
          this.data.GroupNum = element.GroupNum;
          this.data.Currency = element.Currency;
          this.data.ListaCUrrency = false;
          if (this.data.Currency  == "##") {
            this.data.Currency = "USD";
            this.data.ListaCUrrency = true;
          }
        }
      });
    }*/

    var objeto = "SELECT * FROM [CRD1] WHERE [CardCode] = '" + this.data.CardCode + "'";
    var dato: any = await this._api.GetNotFileQuery(objeto);
    dato = dato.objeto;
    this.DESPACHO = [];
    this.FACTURA = [];
    dato.forEach(element => {
      if (element.AdresType == "S") {
        this.DESPACHO.push(element);

      }
      else if (element.AdresType == "B") {
        this.FACTURA.push(element);
      }
    });

    this.DESPACHO.forEach(element => {
      if (this.data.ShipToDef == element.Address) {
        this.cargaDatosDireccionDespacho2(element);
      }
    });


    this.cambioTipoMoneda();
    //
  }

  removeRow(rowIndex: number): void {

    Swal.fire({
      title: "¿Está seguro que desea eliminar la linea?",
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: "Cerrar",
      confirmButtonText: "Si",

    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        this.deleteRow(rowIndex);

      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })

  }

  async textoDirDespacho() {
    var respuesta = "";
    this.TaxCode = "IVA";

    if (this.DESPACHO) {
      this.DESPACHO.forEach(element => {
        if (element.Address == this.data.ShipToDef) {
          this.cargaDatosDireccionDespacho2(element);
          this.TaxCode = element.TaxCode;
          var Street = element.Street;
          var City = element.City;
          var County = element.County;
          if (!Street) {
            Street = "";
          }
          if (!City) {
            City = "";
          }
          if (!County) {
            County = "";
          }

          respuesta = Street + ", " + City + ", " + County;
        }
      });
    }
    this.actualizaImpuesto();
    return respuesta;
  }

  actualizaImpuesto() {

    if (this.data.GroupCode == "104") {

      this.TaxCode = "IVA_EXE";
    } else {
      // this.TaxCode = "IVA";
    }
    this.data.Detalles.forEach(element => {
      element.TaxCode = this.TaxCode;
    });
  }


  textoDirFactura() {
    var respuesta = "";
    if (this.FACTURA) {
      this.FACTURA.forEach(element => {
        if (element.Address == this.data.BillToDef) {
          var Street = element.Street;
          var City = element.City;
          var County = element.County;
          if (!Street) {
            Street = "";
          }
          if (!City) {
            City = "";
          }
          if (!County) {
            County = "";
          }

          respuesta = Street + ", " + City + ", " + County;
        }
      });
    }
    return respuesta;
  }

  probandoCarga: boolean = false;
  async cargaDatosIniciales() {

    if (!this.probandoCarga) {
      Swal.fire({
        title: 'Espere por favor!',
        html: 'Se está cargando documento',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      });
    }


    this.objetoSAP = await this._apiMongo.getObjetosSAP();
    this.objetoSAP = this.objetoSAP.resultado;
    //console.log("Objetos sap", this.objetoSAP);


    this.ObjType = this._activeRoute.snapshot.paramMap.get("ObjType");
    this.DocEntry = this._activeRoute.snapshot.paramMap.get("DocEntry");
    this.Origen = this._activeRoute.snapshot.paramMap.get("Origen");

    this.creaActualiza = "Crear";
    var fechaHoy = new Date();

    //this.data.DocDate = fechaHoy;
    //this.data.TaxDate = fechaHoy;
    //this.data.DocDueDate = fechaHoy;
    this.data.ObjType = this.ObjType;
    //JSON IVAS (OSTC)
    var objeto = "SELECT * FROM [OSTC]";
    var dato: any = await this._api.GetNotFileQuery(objeto);
    this.OSTC = dato.objeto;
    //

    //
    objeto = "SELECT [empID], [lastName], [firstName] FROM [OHEM] WHERE [Active] = 'Y' ORDER BY [lastName] ASC";
    dato = await this._api.GetNotFileQuery(objeto);
    this.OHEM = dato.objeto;
    //
    objeto = "SELECT [SlpCode], [SlpName] FROM [OSLP] WHERE [Active] = 'Y' ORDER BY [SlpName] ASC";
    dato = await this._api.GetNotFileQuery(objeto);
    this.OSLP = dato.objeto;
    //

    objeto = "SELECT * FROM [OCRN] ORDER BY [CurrCode] ASC";
    dato = await this._api.GetNotFileQuery(objeto);
    this.OCRN = dato.objeto;
    //

    objeto = "SELECT [GroupNum], [PymntGroup] FROM [OCTG] ORDER BY [PymntGroup] ASC";
    dato = await this._api.GetNotFileQuery(objeto);
    this.OCTG = dato.objeto;
    //

    objeto = "SELECT * FROM [OSHP]";
    dato = await this._api.GetNotFileQuery(objeto);
    this.OSHP = dato.objeto;
    //

    objeto = "SELECT [WhsCode], [WhsName] FROM [OWHS] WHERE [Inactive] = 'N'";
    dato = await this._api.GetNotFileQuery(objeto);
    this.OWHS = dato.objeto;
    //

    objeto = objeto = await this.obtieneQueryMongo("IMPUESTOS");
    dato = await this._api.GetNotFileQuery(objeto);
    this.IMPUESTOS = dato.objeto;

    this.IMPUESTOS.forEach(element => {
      if(element.Code =="IVA"){
        this.TaxRate = element.Rate;
      }
      console.log(this.TaxRate);
    });

    objeto = await this.obtieneQueryMongo("ValoresTablaUsuario");
    objeto = objeto + " WHERE T0.[TableID] = 'ORDR' AND T0.[AliasID] = 'IND_Tipo_E'";
    dato = await this._api.GetNotFileQuery(objeto);
    this.IND_Tipo_E = dato.objeto;
    //



    //objeto = "SELECT [CardCode], [CardName] FROM [OCRD] WHERE [CardType] != 'S'"//await this.obtieneQueryMongo("ListaClientes");
    objeto = await this.obtieneQueryMongo("ListaClientes");
    dato = await this._api.GetNotFileQuery(objeto);
    this.OCRD = dato.objeto;
    this.OCRDCopy = [...this.OCRD];
    //

    //oculta Bodega por que si nomas
    //this.ocultaColumna("WhsCode");
    //this.ocultaColumna("Quantity");
    // this.ocultaColumna("Price");
    this.ocultaColumna("Factor1");
    this.ocultaColumna("Factor2");
    this.ocultaColumna("BuyUnitMsr");
    this.ocultaColumna("SalUnitMsr");
    // this.ocultaColumna("PriceAfeterDisc");
    if (this.ObjType == "23" || this.ObjType == "17") {
      // this.ocultaColumna("BuyUnitMsr");
    } else {
      this.ocultaColumna("SalUnitMsr");
    }


    await this.cargaDocumentoDesdeSAP();

    //

    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

    if (!this.probandoCarga) {
      Swal.close();
    }

    this.CargarUsuario();
  }

  async clienteSeleccionado(CardCode: string) {

  }

  async asignarVendedorLogueado() {
    //OBTIENE VENDEDOR USUARIO LOGUEADO
    var dato: any = await this._dbService.getAll(variableGlobal.DB_TABLE_GENERAL).toPromise();
    dato = dato.filter(item => item.id == variableGlobal.DB_TABLE_GENERAL_id_login)[0];
    this.data.SlpCode = dato.datos.login.VENDEDOR;
    //
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  async ListaMateriales(item: DocumentoMarketingDetalle, rowIndex) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(listaArticuloComponent, ngbModalOptions);
    modalRef.componentInstance.PriceList = this.data.ListaDePrecioSocio;
    modalRef.componentInstance.Detalle = item;
    modalRef.componentInstance.Encabezado = this.data;
    modalRef.componentInstance.tipoCambio = this.tipoCambio;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {
      if (data) {
        // El modal retorna data que trae los Detalles conteniendo el itemcode
        this.UpdateRow(data, rowIndex);
      }
    }, (reason) => {
      //this.editar(item);
    });
  }

  async UpdateRow(item, rowIndex, origen = "NUEVA_LINEA") {
    console.log(this.data);
    this.data.Detalles = [
      ...this.data.Detalles.map((obj, index) => {
        if (index === rowIndex) {
          //
          if (origen == "PriceAfeterDisc") {
            let precio: number = obj.Price;
            let descuento: number = obj.Disc;
            let precioafterdcto: number = obj.PriceAfeterDisc;

            descuento = (precioafterdcto * 100) / precio;

            descuento = 100 - descuento;


/*
            if (this.ObjType == "23") {

              if (descuento > 15) {
                obj.Disc = 0;
                obj.PriceAfeterDisc = obj.Price;
              } else {
                obj.Disc = descuento;
              }
            }
*/

            obj.Disc = descuento;
            obj.LineTotal = obj.Quantity * obj.PriceAfeterDisc;

          }
          if (origen == "CANTIDAD") {

            let cantidad = obj.Quantity;
            let Factor2 = obj.Factor2;

            if (cantidad! % Factor2) {
              let mult = Math.trunc(cantidad / Factor2);

              cantidad = Factor2 * mult;
              obj.Quantity = cantidad;
            }
          }

          /*
          if (origen == "DESCUENTO") {

            if (this.ObjType == "23") {
              if (obj.Disc > 15) {
                obj.Disc = 15;
              }
            }
          }

*/

          if (origen == "CAJAS") {
            obj.Quantity = obj.Factor2 * obj.Factor1;
          }
          var precioUnitarioTrasDescuneto = (obj.Price * obj.Disc) / 100;
          precioUnitarioTrasDescuneto = obj.Price - precioUnitarioTrasDescuneto;
          var TotalDespuesDescuento = precioUnitarioTrasDescuneto * obj.Quantity;
          obj.PriceAfeterDisc = precioUnitarioTrasDescuneto;
          obj.LineTotal = TotalDespuesDescuento;

          return { ...obj }
        }
        return obj;
      }),
    ];

  }


  async cambiaDatoLinea(item: DocumentoMarketingDetalle, rowIndex, origen) {
    //
    this.UpdateRow(item, rowIndex, origen);
  }

  deleteRow(rowIndex) {
    this.data.Detalles = [...this.data.Detalles.filter((_v, k) => k !== rowIndex)];
  }
  addLine() {
    var maxKey = this.data.Detalles.length.toString();
    this.data.Detalles.forEach(element => {
      if (Number(element.key) >= Number(maxKey)) {
        maxKey = (Number(element.key) + 1).toString();
      }
    });
    //

    if (this.data.GroupCode == "104") {

      this.TaxCode = "IVA_EXE";
    }

    this.data.Detalles = [
      ...this.data.Detalles,
      {
        key: maxKey,
        LineNum: null,
        BaseEntry: -1,
        BaseLine: -1,
        BaseType: null,
        Account: null,
        ItemCode: null,
        Dscription: null,
        Factor1: null,
        Factor2: null,
        Quantity: null,
        Price: null,
        Disc: this.data.DescuentoCampoUsuario,
        PriceAfeterDisc: this.data.DescuentoCampoUsuario,
        LineTotal: null,
        OnHand: null,
        TaxCode: this.TaxCode,
        WhsCode: null,
        DimCode1: null,
        DimCode2: null,
        DimCode3: null,
        DimCode4: null,
        DimCode5: null,
        Currency: this.data.Currency,
        Lote: [],
        Serie: [],
        ManBtchNum: null,
        ManSerNum: null,
        CantidadPickeada: null,
        CantidadPendiente: null,
        ocultaLote: true,
        ocultaSerie: true,
        UserFields: [

      ],
      }
    ];

  }

  UpdateTaxCode() {

    this.data.Detalles.forEach(element => {
      element.TaxCode=this.TaxCode;
    });

    this.IMPUESTOS.forEach(element => {
      if(element.Code == this.TaxCode)
      this.TaxRate=element.Rate;
    });

    this.VatSum();
    console.log(this.TaxCode);
    console.log(this.TaxRate);
    console.log(this.data.Detalles);
  }

  moverIdiceTabla(posicionActual, posicionRemplaza) {

    var detalleClon: DocumentoMarketingDetalle[] = [...this.data.Detalles];
    this.data.Detalles = [];
    //

    var orden = [];
    var vuelta: number = 0;
    detalleClon.forEach(element => {
      var key = element.key;
      var index2 = vuelta;

      if (vuelta == posicionActual) {
        index2 = posicionRemplaza;
      }
      if (vuelta == posicionRemplaza) {
        index2 = posicionActual;
      }

      var json = {
        index: vuelta,
        index2: index2,
        key: key
      }
      orden.push(json);
      vuelta++;
    });

    orden.forEach(element => {
      var detalleRow: DocumentoMarketingDetalle = detalleClon[element.index2];
      detalleRow.key = element.key;
      this.data.Detalles = [
        ...this.data.Detalles,
        detalleRow
      ]
    });

    //

  }

  public clicked: string;
  eventEmitted($event: { event: string; value: any }): void {
    //this.clicked = JSON.stringify($event);
    //
    //

    if ($event.event == "onRowDrop") {
      this.moverIdiceTabla($event.value.currentIndex, $event.value.previousIndex);

    }
  }


  GrossProfit() {
    var sumDetalle: number = 0
    this.data.Detalles.forEach(element => {
      sumDetalle += element.LineTotal;
    });
    this.data.GrossProfit = sumDetalle;
    return this.data.GrossProfit;
  }

  DiscTotal() {
    this.data.DiscTotal = (this.data.GrossProfit * this.data.DiscPrcnt) / 100;
    //
    return this.data.DiscTotal;
  }
  cambiaInput(evento) {
    if (evento.target.name = "DiscPrcnt") {
      this.data.DiscPrcnt = Number(evento.target.value);
    }
    //
  }
  VatSum() {

    var sumDetalleImpuesto = 0;
    var impuesto = this.TaxRate;
    if (this.data.Detalles) {
      this.data.Detalles.forEach(element => {
        // console.log("TAX CODE: "+element.TaxCode);
        // impuesto = this.getOSTCRate(element.TaxCode);

        if (element.VATLiable == "N" || this.TaxCode=="IVA_EXE") {
          impuesto = 0;
        }
        //calcular % del descuento general en base al total antes de descuentos
        var porcenjateTotal = (element.LineTotal * 100) / this.data.GrossProfit;
        //var totalDescuentoLL3evadoAlinea = (this.data.DiscTotal * porcenjateTotal) / 100;
        //sumDetalleImpuesto += ( (element.LineTotal + totalDescuentoLL3evadoAlinea) * impuesto) / 100;
        sumDetalleImpuesto += ((element.LineTotal) * impuesto) / 100;
      });

      this.data.VatSum = sumDetalleImpuesto;

    }
    return this.data.VatSum;
  }

  getOSTCRate(code) {
    var impuesto = 0;
    if (this.OSTC) {
      this.OSTC.forEach(element => {
        if (element.Code == code) {
          impuesto = element.Rate;
        }
      });
    }
    return impuesto;
  }

  /*TotalExpns()
  {
    return this.data.TotalExpns;
  }*/
  DocTotal() {
    var total = (this.data.GrossProfit - this.data.DiscTotal) + this.data.VatSum;
    this.data.DocTotal = total;
    return this.data.DocTotal;
  }

  async generarDocumento() {
    Swal.fire({
      title: 'Espere por favor!',
      html: 'Se está procesando el documento',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });

    // console.log("DOCENTRY: "+this.data.DocEntry);
    let noborrar = []


    this.data.UserFields = noborrar;

    // Añade comentarios al documento generado en SAP
    //if (this.data.Comments) {
    //  if (!this.data.Comments.includes(this.usuario.NOMBRE)) {
    //    this.data.Comments = this.usuario.NOMBRE + "\r\n" + this.data.Comments;
    //  }
    //}
    //else {
    //  this.data.Comments = this.usuario.NOMBRE + "\r\n";
    //}


    var datos: any;
    var aprobacion: any = await this._apiIndesad.EnviaDocumentoAprobacion(this.data);//VerificarDocumentoAprobación
    var solicitud: SolicitarAprobacion = aprobacion.resultado;


    if (aprobacion.resultado.length > 0) {

      aprobacion.resultado.forEach(element => {
        // console.log("Aprobación resultado: "+Object.values(Object.values(element)));
        element.MENSAJE = "";
      });

      var preliminar: DOCUMENTO_MARKETING_PRELIMINAL = {
        APROBACION: aprobacion.resultado,
        ObjType: this.ObjType,
        Documento: this.data,
        usuario: this.usuario,
        APROBADO: false,
      }

      // console.log("Preliminar: "+preliminar);
      Swal.close();
      // Se solicita aprobación para el documento de nota de venta
      console.log("Doc entry: " + this.data.DocEntry);
      // if (this.data.DocEntry > 0) {
      //   var res = await this.SolicitarAprobacion(preliminar);
      // }
      var res = await this.SolicitarAprobacion(preliminar);


      return;
    } else {
      //Swal.fire("Mensaje", "Creacion de documentos deshabilitada", "error");
      //return;
      datos = await this._apiSap.GeneraDocumentoMarketing(this.data);
      if (datos.estado) {
        //this.creaActualiza = "Actualizar";
        //this.data = datos.resultado;
        //Swal.fire("Mensaje", datos.mensaje, "success");
        Swal.fire({
          title: datos.mensaje,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          icon: "success"
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.reload();
          }
        })

      }
      else {
        Swal.fire("Mensaje", datos.mensaje, "error");
      }
    }

  }


  nombreCampo(field: String) {
    var descripcion: string = "";
    var objType = this.data.ObjType;
    // if (objType == '540000006') {
    //   objType = '22';
    // }
    if (this.objetoSAP) {
      var filtro = this.objetoSAP.filter(a => a.ObjType == objType);
      var filtro2 = filtro[0].Fields.filter(a => a.Field == field);
      // console.log("Filtro2: "+Object.values(filtro2));
      descripcion = filtro2[0].Description;
    }
    /*
    if (objType == "23" || objType == "17") {
      if (field == "CardCode") {
        descripcion = "Cliente";
      }
      else if (field == "ShipToDef") {
        descripcion = "Dirección de despacho";
      }
      else if (field == "BillToDef") {
        descripcion = "Dirección de factura";
      }
      else if (field == "GroupNum") {
        descripcion = "Condición de pago";
      }
      else if (field == "TrnspCode") {
        descripcion = "Forma de envio";
      }
      else if (field == "DocDueDate") {
        descripcion = "Fecha Estimada Entrega";
      }
      else if (field == "SlpCode") {
        descripcion = "Ejecutivo comercial";
      }
      else if (field == "DocumentDetails") {
        descripcion = "Detalle de venta";
      }
    }
    else if (objType == "540000006" || objType == "22") { //compras
      if (field == "CardCode") {
        descripcion = "Proveedor";
      }
      else if (field == "ShipToDef") {
        descripcion = "Dirección de recepción";
      }
      else if (field == "BillToDef") {
        descripcion = "Dirección de factura";
      }
      else if (field == "GroupNum") {
        descripcion = "Condición de pago";
      }
      else if (field == "TrnspCode") {
        descripcion = "Forma de recepción";
      }
      else if (field == "DocDueDate") {
        descripcion = "Fecha Estimada Recepción";
      }
      else if (field == "SlpCode") {
        descripcion = "Ejecutivo de compras";
      }
      else if (field == "DocumentDetails") {
        descripcion = "Detalle de compra";
      }
    }*/


    return descripcion;
  }

  EsCotizacion() {
    // if(this.usuario.RUT=="10788816-0" || this.usuario.RUT=="16608417-2"){
    if (this.usuario.ADMIN || (this.ObjType != "23" && this.ObjType != "17")) {
      return false;
    } else {
      return true;
    }


    return true;
    if (this.ObjType == "23") {
      return true;
    } else {
      return false;
    }

  }

  CargarUsuario() {

  }
  UploadFiles() {


  }
  async SolicitarAprobacion(respuesta) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "lg",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(ModeloAprobacionCRMComponent, ngbModalOptions);
    modalRef.componentInstance.obj = respuesta;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((datos) => {
      console.log(datos);
      if (datos) {
        if (datos.estado) {
          //this.creaActualiza = "Actualizar";
          //this.data = datos.resultado;
          //Swal.fire("Mensaje", datos.mensaje, "success");
          Swal.fire({
            title: datos.mensaje,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Ok',
            icon: "success"
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              location.reload();
            }
          })

        }
        else {
          Swal.fire("Mensaje", datos.mensaje, "error");
        }
      }


    }, (reason) => {
      //this.editar(item);
    });

  }
}
