
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">{{titulo}}</h3>
            </div>

            <!-- /.card-header -->
            <div class="card-body">

                <div class="row">

                    <div class="columns col-12">
                    <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns">
                    </ngx-table>
                    <ng-template #frmNumeroDocumento let-row let-rowIndex="rowIndex">
                        <!--<a href="/Layoult/Venta/{{row.DocEntry}}/{{ObjTypeDoc}}" target="_blank">{{row.DocNum}}</a>-->
                        <button class="btn btn-info btn-sm" (click)="abrirLayout(row)">{{row.DocNum}}</button>

                    </ng-template>
                    <ng-template #frmEstado let-row let-rowIndex="rowIndex">
                        <span class="badge badge-primary" *ngIf="row.DocStatus=='O'">Abierta</span>
                        <span class="badge badge-danger" *ngIf="row.DocStatus=='C'">Cerrada</span>
                    </ng-template>
                    <ng-template #frmVendedor let-row let-rowIndex="rowIndex">
                        {{this.vendedorCodigoATexto(row.SlpCode)}}
                    </ng-template>
                    <ng-template #frmFechaDocumento let-row let-rowIndex="rowIndex">
                        {{row.DocDate | date: 'dd/MM/yyyy'}}
                    </ng-template>
                    <ng-template #frmFechaEntrega let-row let-rowIndex="rowIndex">
                        {{row.DocDueDate | date: 'dd/MM/yyyy'}}
                    </ng-template>
                    <ng-template #frmComments let-row let-rowIndex="rowIndex" class="frmComments">
                       <p style="white-space: normal;">{{row.Comments}}</p>
                    </ng-template>

                    <ng-template #frmAccion let-row let-rowIndex="rowIndex">
                        <div class="input-group input-group-lg mb-3">
                            <div class="input-group-prepend dropleft">
                                <button type="button" class="btn btn-warning dropdown-toggle btn-sm" data-toggle="dropdown" aria-expanded="false"></button>
                                <ul class="dropdown-menu"  x-placement="left-start" *ngIf="tipoSeguimiento=='COT'">
                                    <li class="dropdown-item">                                  <a href="/Venta/23/{{row.DocEntry}}/NEW" class="form-control btn btn-warning">              Copiar</a></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <a href="/Venta/23/{{row.DocEntry}}/17" class="form-control btn btn-warning">               Copiar a NV</a></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <a href="/Venta/23/{{row.DocEntry}}/13" class="form-control btn btn-warning">               Copiar a Factura</a></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <a href="/Venta/23/{{row.DocEntry}}" class="form-control btn btn-danger" target="_blank">   Editar</a></li>
                                    <li class="dropdown-item">                                  <button (click)="EnviaCoreo(row)" class="form-control btn btn-success" target="_blank">     Enviar</button></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <button (click)="CerrarDoc(row)" class="form-control btn btn-danger" target="_blank">       Cerrar cotización</button></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <button (click)="generarNV(row)"  class="form-control btn btn-success" target="_blank">     Generar NV</button></li>
                                </ul>

                                <ul class="dropdown-menu" x-placement="left-start" *ngIf="tipoSeguimiento=='NV'">
                                    <li class="dropdown-item"><a href="/Venta/17/{{row.DocEntry}}/NEW" class="form-control btn btn-warning" target="_blank">Copiar</a></li>
                                    <li class="dropdown-item"><a href="/Venta/17/{{row.DocEntry}}" class="form-control btn btn-danger" target="_blank">Editar</a></li>
                                    <li class="dropdown-item"><button (click)="EnviaCoreo(row)" class="form-control btn btn-success" target="_blank">Enviar</button></li>
                                    <li class="dropdown-item"><button (click)="CerrarDoc(row)" class="form-control btn btn-danger" target="_blank">Cerrar nota de venta</button></li>
                                </ul>
                                <!-- Factura -->
                                <ul class="dropdown-menu" x-placement="left-start" *ngIf="tipoSeguimiento=='FAC'">
                                    <li class="dropdown-item"><button (click)="EnviaCoreo(row)" class="form-control btn btn-success" target="_blank">Enviar</button></li>
                                </ul>
                                <!--COMPRA-->
                                <ul class="dropdown-menu"  x-placement="left-start" *ngIf="tipoSeguimiento=='OFECOM'">
                                    <li class="dropdown-item">                                  <a href="/Compra/540000006/{{row.DocEntry}}/NEW" class="form-control btn btn-warning">              Copiar</a></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <a href="/Compra/540000006/{{row.DocEntry}}/22" class="form-control btn btn-warning">               Copiar a pedido en transito</a></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <a href="/Compra/540000006/{{row.DocEntry}}" class="form-control btn btn-danger" target="_blank">   Editar</a></li>
                                    <li class="dropdown-item">                                  <button (click)="EnviaCoreo(row)" class="form-control btn btn-success" target="_blank">             Enviar</button></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <button (click)="CerrarDoc(row)" class="form-control btn btn-danger" target="_blank">               Cerrar pedido a fabrica</button></li>
                                    <li class="dropdown-item" [hidden]="row.DocStatus == 'C'">  <button (click)="generarOC(row)"  class="form-control btn btn-success" target="_blank">             Generar pedido en transito</button></li>
                                </ul>
                                <ul class="dropdown-menu" x-placement="left-start" *ngIf="tipoSeguimiento=='COMPRA'">
                                    <li class="dropdown-item"><a href="/Compra/22/{{row.DocEntry}}/NEW" class="form-control btn btn-warning" target="_blank">Copiar</a></li>
                                    <li class="dropdown-item"><a href="/Compra/22/{{row.DocEntry}}" class="form-control btn btn-danger" target="_blank">Editar</a></li>
                                    <li class="dropdown-item"><button (click)="EnviaCoreo(row)" class="form-control btn btn-success" target="_blank">Enviar</button></li>
                                    <li class="dropdown-item"><button (click)="CerrarDoc(row)" class="form-control btn btn-danger" target="_blank">Cerrar pedido en transito</button></li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>

                </div>
                </div>

            </div>
        </div>
    </div>
</div>
