<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Inventario Comercial <i class="fas fa-solid fa-download" (click)="exportToCSV()"></i>
        </h3>

        <div (click)="verCart()" class="cart-container">
          <i class="fa-solid fa-cart-shopping cart-icon"></i>
          <i class="fa-solid fa-circle cart-circle">
            <div class="cart-number">
              {{carritoCantidad}}
            </div>
          </i>
        </div>

        <div class="cart-container">

          <div class="cart-modal" *ngIf="carritoModal">
            <p class="cart-modal-title">Carrito</p>
            <mat-list>
                <table>

                  <tr>
                    <th class="table-separador">Borrar</th>
                    <th class="table-separador">P. Neto</th>
                    <th class="table-separador">P. Bruto</th>
                    <th class="table-separador">Nombre</th>
                  </tr>

                  <tr *ngFor="let item of carrito;let i = index">
                    <td class="table-separador">
                      <button (click)="borrarItemCarrito(i)" mat-mini-fab class="cart-modal-boton-borrar">
                        <i class="fa-sharp fa-solid fa-trash cart-modal-icon-borrar"></i>
                      </button>
                    </td>
                    <td class="table-separador">{{item.Price | iNDCLP}}</td>
                    <td class="table-separador">{{item.PriceBruto | iNDCLP}}</td>
                    <td class="table-separador">{{item.ItemName}}</td>
                  </tr>

                  <tr>
                    <td class="table-separador">Total:</td>
                    <td class="carritoTotal table-separador">{{carritoTotalNeto | iNDCLP}}</td>
                    <td class="table-separador">{{carritoTotalBruto | iNDCLP}}</td>
                    <td class="table-separador"></td>
                  </tr>

                </table>

            </mat-list>
          </div>

        </div>

      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns"></ngx-table>
            <ng-template #cart let-row let-rowIndex="rowIndex">
              <button (click)="addCart(row)" mat-mini-fab style="background: #71299A" aria-label="Example icon button with a filter list icon">
                <i class="fa-sharp fa-solid fa-cart-plus" style="color: white;font-size: 20px;"></i>
              </button>
            </ng-template>
            <ng-template #frmItemCode let-row let-rowIndex="rowIndex">
              <button class="btn btn-primary" style="background: #71299A;border: 1px solid #71299A;" (click)="AbrirDetalleItem(row)">{{row.ItemCode}}</button>
            </ng-template>
            <ng-template #frmPrice let-row let-rowIndex="rowIndex">
              <!-- <div>{{row.Price | iNDCLP}}</div> -->
              <!-- <div *ngIf="row.Currency != 'CLP'">{{row.Price | currency: row.Currency}}</div> -->
              <div *ngIf="row.Currency != 'CLP'">{{row.Price | iNDCLP}}</div>
              <div *ngIf="row.Currency == 'CLP'">{{row.Price | iNDCLP}}</div>
            </ng-template>

            <ng-template #frmPriceBruto let-row let-rowIndex="rowIndex">
              <div *ngIf="row.Currency != 'CLP'">{{row.PriceBruto | iNDCLP}}</div>
              <div *ngIf="row.Currency == 'CLP'">{{row.PriceBruto | iNDCLP}}</div>
            </ng-template>

          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>
