import { Component, OnInit } from '@angular/core';
import {variableGlobal} from './../../global.enum';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IntranetoldService } from 'src/app/api/intranetold.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit {


  constructor(
    private _dbService: NgxIndexedDBService,
    private _route: Router,
    private Location:Location,
    private route: ActivatedRoute,
    private _api:IntranetoldService,

  ) {

   }
   general = variableGlobal;
   menuAll:any;
   currentURL = "";
   login:any = {
    "NOMBRE": ""
   };
   perfiles;

  async ngOnInit(): Promise<void> {
    //console.log(this.general);



    this.currentURL = this.Location.path();
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu_final).subscribe(
      menu => {
        var manux:any = menu;
        this.menuAll = manux.datos;

        //console.log("cargando url con esta url ", this.currentURL);
        //console.log(this.menuAll);
      }
    );

    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      item => {
        var itemx:any = item;
        this.login = itemx.datos.login;

      }
    );



    this._route.routeReuseStrategy.shouldReuseRoute = () => false;



  }

  cambiaURL()
  {
    //window.location.href = url;
    //this._route.navigate([url]);
    //console.log("Cambiando url");
    //window.location.reload();
    this.currentURL = this.Location.path();
    console.log(this.currentURL);


  }

}
